import AOS from 'aos';
import Vue from 'vue/dist/vue.js';
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import CoverVideo from './components/cover-video';
import ImagesGallery from './components/images-gallery';

// Animations on scroll
AOS.init({
  duration: 600,
  disable: 'mobile',
});

// eslint-disable-next-line
const app = new Vue({
  el: '#app',
  data: {
    message: 'Hello Vue !',
  },
  components: {
    Swiper,
    SwiperSlide,
    CoverVideo,
    ImagesGallery,
  },
  directives: {
    swiper: directive,
  },
  methods: {
    toggleMobileNav() {
      // Toggles mobile nav display (with css transitions)
      if (this.$refs.topNav.classList.contains('is-opened')) {
        this.$refs.topNavHamburger.classList.remove('is-active');
        this.$refs.topNav.classList.add('is-closing');
        setTimeout(() => {
          this.$refs.topNav.classList.remove('is-opened');
          this.$refs.topNav.classList.remove('is-closing');
        }, 500);
      } else {
        this.$refs.topNavHamburger.classList.add('is-active');
        this.$refs.topNav.classList.add('is-opened');
      }
    },
  },
});
